import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { axiosAccount, axiosAPI } from "../axios";
import Modal from "../components/Modal";
import { toast } from "react-toastify";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "./Tooltip";
const ServiceCard = ({ service }) => {
    const notifyError = (message) =>
        toast.error(message, {
            position: "top-left",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    const notifySuccess = (message) =>
        toast.success(message, {
            position: "top-left",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const notifyWarning = (message) =>
        toast.warning(message, {
            position: "top-left",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const [isOpen, setIsOpen] = useState(false);
    const [orderDetails, setOrderDetails] = useState({
        fullname: "",
        email: "",
        phone: "",
        service: null,
    });
    const service_order = async (event) => {
        event.preventDefault();
        console.log(orderDetails);
        console.log(orderDetails?.service?.id);
        try {
            const response = await axiosAPI({
                url: "place-order",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    fullname: event.target.fullname.value,
                    email: event.target.email.value,
                    phone: event.target.phone.value,
                    promoter: orderDetails?.service?.promoter?.id,
                    service: orderDetails?.service?.id,
                },
            });
            console.log(response);
            setIsOpen(false);
            if (response.status === 200) {
                notifySuccess(
                    "تم تسجيل طلبكم بنجاح, سيتم التواصل معكم في حالة توفر هذه الخدمـة"
                );
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <>
            <TooltipProvider delayDuration={300}>
                <Tooltip>
                    <TooltipTrigger>
                        <div
                            dir="rtl"
                            class="flex flex-col h-full bg-white border border-slate-200 shadow shadow-slate-950/5 rounded-2xl overflow-hidden"
                        >
                            <img
                                class=" object-cover rounded-t-lg object-cover h-48 w-full"
                                width="304"
                                height="192"
                                src={`${process.env.REACT_APP_DOMAIN}${service?.image}`}
                                alt=""
                            />
                            <div class="flex-1 flex flex-col p-6">
                                <div class="flex-1">
                                    <header class="mb-2">
                                        <h5 class="mb-2 text-2xl font-bold tracking-tight text-primary ">
                                            {service?.name}
                                        </h5>
                                    </header>

                                    <p class=" line-clamp-3 mb-3 font-normal text-slate-700 text-justify">
                                        {service?.description}
                                    </p>
                                </div>

                                <div class="flex justify-end space-x-2">
                                    <button
                                        onClick={() => {
                                            setOrderDetails({
                                                fullname: "",
                                                email: "",
                                                phone: null,
                                                service: service,
                                            });
                                            setIsOpen(true);
                                        }}
                                        type="button"
                                        className=" text-white bg-primary self-end rounded-3xl py-2 px-4 w-fit m-auto mt-3 mb-4"
                                    >
                                        {" "}
                                        طــلب الخدمة{" "}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div>
                                <img
                                    class="rounded-t-lg object-cover h-48 w-full"
                                    width="304"
                                    height="192"
                                    src={`${process.env.REACT_APP_DOMAIN}${service?.image}`}
                                    alt=""
                                />

                                <div class="p-5">
                                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-primary ">
                                        {service?.name}
                                    </h5>

                                    <p class="mb-3 font-normal text-slate-700 text-justify">
                                        {service?.description}
                                    </p>
                                </div>
                                <div className="w-full px-4 flex flex-col justify-evenl justify-between mt-auto">
                                    <button
                                        onClick={() => {
                                            setOrderDetails({
                                                fullname: "",
                                                email: "",
                                                phone: null,
                                                service: service,
                                            });
                                            setIsOpen(true);
                                        }}
                                        type="button"
                                        className=" text-white bg-primary self-end rounded-3xl py-2 px-4 w-fit m-auto mt-3 mb-4"
                                    >
                                        {" "}
                                        طــلب الخدمة{" "}
                                    </button>
                                </div>
                            </div> */}
                    </TooltipTrigger>
                    <TooltipContent>
                        <div
                            dir="rtl"
                            class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                        >
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-primary ">
                                {service?.name}
                            </h5>
                            <p class="flex items-baseline text-slate-500">
                                {service?.description}
                            </p>
                        </div>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>

            <Modal
                trigger={isOpen}
                setTrigger={setIsOpen}
                header={"طلب الخدمـة"}
                modalWidth={"1000px"}
            >
                <form
                    onSubmit={service_order}
                    className="flex flex-col gap-6 px-4 mx-auto items-center md:flex-row md:flex-wrap md:justify-center lg:grid grid-cols-12"
                >
                    <input
                        id="fullname"
                        name="fullname"
                        required
                        value={orderDetails?.fullname}
                        onChange={(e) =>
                            setOrderDetails((prevState) => ({
                                ...prevState,
                                fullname: e.target.value,
                            }))
                        }
                        className="w-full lg:col-span-12 placeholder:text-sm placeholder:text-primary placeholder:leading-4 text-right px-2 py-2 rounded-lg bg-[#F1F3F8] "
                        type="text"
                        placeholder="الإسـم الكامل"
                    />
                    <input
                        id="email"
                        name="email"
                        required
                        value={orderDetails?.email}
                        onChange={(e) =>
                            setOrderDetails((prevState) => ({
                                ...prevState,
                                email: e.target.value,
                            }))
                        }
                        className="w-full lg:col-span-12 placeholder:text-sm placeholder:text-primary placeholder:leading-4 text-right px-2 py-2 rounded-lg bg-[#F1F3F8] "
                        type="email"
                        placeholder="البريد الإلكتروني"
                    />
                    <input
                        id="phone"
                        name="phone"
                        required
                        value={orderDetails?.phone}
                        onChange={(e) =>
                            setOrderDetails((prevState) => ({
                                ...prevState,
                                phone: e.target.value,
                            }))
                        }
                        className="w-full lg:col-span-12 placeholder:text-sm placeholder:text-primary placeholder:leading-4 text-right px-2 py-2 rounded-lg bg-[#F1F3F8] "
                        type="number"
                        placeholder="رقم الهاتف"
                    />
                    <div className="flex gap-3 flex-col w-full lg:col-span-12 text-sm leading-4 text-right p-2 ">
                        <span className="text-md">:اسـم الخدمة</span>
                        <span className="rounded-lg text-primary bg-[#F1F3F8] p-2">
                            {orderDetails?.service?.name}
                        </span>
                    </div>
                    <div className="flex gap- flex-row-reverse items-center w-full lg:col-span-12 text-sm leading-4 text-right p-2 ">
                        <span className="text-md">:مقدم الخدمة</span>
                        <Link
                            to={
                                "/promoters/" +
                                orderDetails?.service?.promoter?.id
                            }
                            className="rounded-lg text-primary p-2"
                        >
                            {orderDetails?.service?.promoter?.first_name +
                                " " +
                                orderDetails?.service?.promoter?.last_name}
                        </Link>
                    </div>
                    <div className="flex gap- flex-row-reverse items-center w-full lg:col-span-12 text-sm leading-4 text-right p-2 ">
                        <span className="text-md">:سعر الخدمة</span>
                        <span className="rounded-lg text-primary p-2">
                            {" "}
                            {orderDetails?.service?.price} DA{" "}
                        </span>
                    </div>
                    <div className="flex col-span-full w-full gap-3 flex-shrink-0 flex-wrap items-center justify-between rounded-b-md border-t-2 border-neutral-100 border-opacity-100 pt-4 mt-4 mr-1 dark:border-opacity-50">
                        <button
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            type="button"
                            className="inline-block hover:bg-gray-200 rounded bg-primary-100 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-primary-700  duration-150 hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 transition-colors ease-linear duration-400"
                        >
                            رجوع
                        </button>
                        <button
                            type="submit"
                            className="ml-1  inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                        >
                            طلب الخدمـة{" "}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default ServiceCard;
