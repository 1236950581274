import React from "react";
import { Link } from "react-router-dom";
const TeacherCard = ({ promoter }) => {
    return (
        <>
            <Link
                to={`/promoters/${promoter?.id}`}
                className=" text-slate-700 hover:text-slate-400"
            >
                <div className="bg-gray-40 relative text-center shadow-[9px_11px_69px_0px_#00000025] hover:shadow-[9px_1px_69px_16px_#00000025] my-8 mb-16 rounded-xl flex flex-col ">
                    {/* PROMOTER PICTURE */}
                    <div className="h-36 w-36 mx-auto -mt-16 mb-8">
                        <img
                            src={`${process.env.REACT_APP_DOMAIN}${promoter?.image}`}
                            alt="promoter"
                            className=" object-cover w-full h-full shadow-xl rounded-full align-middle border-[3px] border-primary"
                        />
                    </div>

                    {/* PROMOTER DETAILS */}
                    <div className=" h-full bg-green-0 text-center mx-6">
                        <h3 className="text-2xl text-primary font-bold leading-normal truncate ">
                            {promoter?.first_name + " " + promoter.last_name}
                        </h3>
                    </div>
                    {/* BIO SECTION */}
                    <div className="mt-2 mb-8  border-slate-200">
                        <p className="w-full font-light text-xl text-nowrap leading-relaxed truncate whitespace-normal overflow-hidden text-primary ">
                            {promoter?.bio}
                        </p>
                    </div>
                </div>
            </Link>
        </>
    );
};

export default TeacherCard;
